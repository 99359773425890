import http from '../utils/request.js'


const stock = {
    getStockInfoAnalysis(limit, start, id) {
        if (!id) id = 1
        const params = {
            workshop_id: id,
            limit: limit,
            start: start
        }
        return http.post(`/screen/iventory`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
    },
    getStockListAnalysis(limit, start) {
        // if (!id) id = 1
        return http.get(`/inventory/inventorys?limit=${limit}&start=${start}`)
    }

}

export default stock;
