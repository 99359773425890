<template>
  <div class="stock" ref="appRef">
    <div class="bg">

      <MyHeader :title="title"></MyHeader>
      <!--      <img style="display: block;-webkit-user-select: none;margin: auto;background-color: hsl(0, 0%, 25%);"-->
      <!--           src="http://192.168.31.66:4747/video">-->
      <!--      <dv-decoration-6 style="width:100%;height:10px;"/>-->
      <div class="stock-container">
        <div class="left">
          <dv-border-box12>
            <div class="left-box">
              <div v-for="item in itemsData" :key="item.name" class="item">
                <div class="item-title">
                  <dv-decoration-3 style="width:50px;height:30px;"/> &nbsp;&nbsp;
                  {{ item.name }}
                </div>
                <div class="item-detail">
                  <div v-for="(model, index) in item.models" :key="model" class="detail-content">
                    <!--              <div class="item-img">图片</div>-->
                    <img :src="item.images[index]" width="120px" height="120px">
                    <div class="item-type">{{ model }}</div>
                    <div class="item-stock_num">库存数: {{ item.stock[index] }}</div>
                  </div>
                </div>
                <dv-decoration-10 style="width:1100px;height:5px;"/>
              </div>
              <div class="block">
                <el-pagination
                    layout="prev, pager, next"
                    :total="this.total_size"
                    :page-size="this.page_size"
                    @current-change="handleItemChange">
                </el-pagination>
              </div>
            </div>
          </dv-border-box12>
        </div>
        <div class="right">
          <dv-border-box12>
            <div class="workpiece-table">
              <el-table
                  :data="this.stock_details"
                  border
                  style="width: 100%">
                <el-table-column
                    prop="field1"
                    label="工件名称"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="item_type"
                    label="工件类型"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="iventory_num"
                    label="库存数量"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="description"
                    label="其他描述">
                </el-table-column>
              </el-table>
            </div>
            <div class="block">
              <el-pagination
                  :hide-on-single-page="true"
                  layout="prev, pager, next"
                  :total="this.table_total_size"
                  :page-size="this.table_page_size"
                  @current-change="handleTableChange">
              </el-pagination>
            </div>
          </dv-border-box12>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import MyHeader from "../../components/header/myHeader.vue";
import drawMixin from "@/utils/drawMixin";
import stock from "@/api/stock";
// import mock from "@/api/mock";

export default defineComponent({
  name: "stock",
  mixins: [drawMixin],
  data() {
    return {
      title: "库存看板",
      itemsData: [],
      itemsData1: [
        {
          name: '联轴器',
          models: ['刚性联轴器', '挠性联轴器'],
          images: [require('@/assets/img/001.png'), require("@/assets/img/002.png")],
          stock: [100, 225]
        },
        {
          name: '轴承',
          models: ['滚珠轴承', '圆柱滚子轴承', '滚针轴承'],
          images: [require("@/assets/img/003.png"), require("@/assets/img/004.png"), require("@/assets/img/005.png")],
          stock: [218, 412, 213]
        },

        // Add more items as needed
      ],
      itemsData2: [
        {
          name: '联轴器',
          models: ['刚性联轴器', '挠性联轴器'],
          images: [require('@/assets/img/001.png'), require("@/assets/img/002.png")],
          stock: [100, 225]
        },
        // Add more items as needed
      ],
      total_size: 4,
      page_size: 2,
      current_page: 1,

      table_total_size: 10,
      table_page_size: 10,
      table_current_page: 1,

      stock_details: [],
      stock_details1: [{
        workpiece_name: '联轴器',
        workpiece_type: '刚性联轴器',
        stock_number: 100,
        description: '无'
      }, {
        workpiece_name: '联轴器',
        workpiece_type: '挠性联轴器',
        stock_number: 225,
        description: '无'
      }, {
        workpiece_name: '轴承',
        workpiece_type: '滚珠轴承',
        stock_number: 218,
        description: '无'
      }, {
        workpiece_name: '轴承',
        workpiece_type: '圆柱滚子轴承',
        stock_number: 412,
        description: '无'
      }],
      stock_details2: [{
        workpiece_name: '联轴器',
        workpiece_type: '刚性联轴器',
        stock_number: 100,
        description: '无'
      }, {
        workpiece_name: '联轴器',
        workpiece_type: '挠性联轴器',
        stock_number: 225,
        description: '无'
      }],
    }
  },
  components: {
    MyHeader,
  },
  mounted() {
    // setInterval(() => {
    //   this.fetchData();
    // }, 3000);
    // setInterval(() => {
    //   this.fetchTableData();
    // }, 3000);
  },
  created() {
    this.fetchData();
    this.fetchTableData();
  },
  methods: {
    handleItemChange(val) {
      this.current_page = val;
      this.fetchData();
    },
    handleTableChange(val) {
      this.table_current_page = val;
      this.fetchTableData();
    },
    fetchData() {
      stock.getStockListAnalysis(this.page_size, this.current_page).then(response => {
        console.log(response);
        let total = response.data.data.total;

        stock.getStockListAnalysis(total, 1).then(response => {
          console.log(response);
          let data = response.data.data.item;
          let allItems = [];
          console.log(data)
          data.forEach(item => {
            let index = allItems.findIndex(value => value.name === item.field1);
            if (index === -1) {
              allItems.push({
                name: item.field1,
                models: [item.item_type],
                images: [require('@/assets/img/001.png')],
                stock: [item.iventory_num]
              })
            } else {
              allItems[index].models.push(item.item_type);
              allItems[index].images.push(require('@/assets/img/001.png'));
              allItems[index].stock.push(item.iventory_num);
            }
          })
          console.log(allItems, '----')
          this.total_size = allItems.length
          let start = (this.current_page - 1) * this.page_size, end = start + this.page_size;
          this.itemsData = allItems.slice(start, end)
          console.log(this.itemsData)
        })
      })
    },
    fetchTableData() {
      stock.getStockListAnalysis(this.table_page_size, this.table_current_page).then(response => {
        console.log(response);
        this.table_total_size = response.data.data.total;
        this.stock_details = response.data.data.item;
      })
    },
  }
})
</script>

<style scoped lang="scss">
@import '../../assets/scss/stock.scss';
</style>
